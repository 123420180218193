/*
 *
 * ForgotPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/ForgotPage/DEFAULT_ACTION',
  CLOSE_SENT_MODAL = 'app/ForgotPage/CLOSE_SENT_MODAL',
  SUBMIT_FORGET_PASSWORD = 'app/ForgotPage/SUBMIT_FORGET_PASSWORD',
  FORGET_PASSWORD_REQUEST = 'app/ForgotPage/FORGET_PASSWORD_REQUEST',
  FORGET_PASSWORD_SUCCESS = 'app/ForgotPage/FORGET_PASSWORD_SUCCESS',
  FORGET_PASSWORD_FAILURE = 'app/ForgotPage/FORGET_PASSWORD_FAILURE',
}

export default ActionTypes;
