/*
 * ResetPage Messages
 *
 * This contains all the text for the ResetPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ResetPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Đặt lại mật khẩu',
  },
  yourPasswordIsReset: {
    id: `${scope}.yourPasswordIsReset`,
    defaultMessage: 'Mật khẩu của bạn đã được đặt lại!',
  },
});
