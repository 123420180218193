import { take, fork, call, put } from 'redux-saga/effects';
import { forgetPassword as forgetPasswordActions } from './actions';
import ActionTypes from './constants';
import { forgetPassword } from 'utils/apollo';

function* submitForgetPasswordFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SUBMIT_FORGET_PASSWORD);
    yield put(forgetPasswordActions.request());
    const response = yield call(forgetPassword, payload);

    if (!response.errors) {
      yield put(forgetPasswordActions.success(response));
    } else {
      yield put(forgetPasswordActions.failure(response.errors));
    }
  }
}

// Individual exports for testing
export default function* forgotPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(submitForgetPasswordFlow);
}
