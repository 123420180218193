/*
 *
 * ForgotPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';
import {} from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const closeSentModal = () => action(ActionTypes.CLOSE_SENT_MODAL);
export const submitForgetPassword = (data: any) => action(ActionTypes.SUBMIT_FORGET_PASSWORD, data);
export const forgetPassword = createAsyncAction(
  ActionTypes.FORGET_PASSWORD_REQUEST,
  ActionTypes.FORGET_PASSWORD_SUCCESS,
  ActionTypes.FORGET_PASSWORD_FAILURE,
)<void, {}, Error>();
