/**
 *
 * ForgotPage
 *
 */

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import translations from 'translations';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose, Dispatch } from 'redux';
import styled from 'styled-components';

import ForgotForm from 'components/ForgotForm/ForgotForm';
import Modal from 'components/Modal/Modal';
import PromptMessage from 'components/PromptMessage/PromptMessage';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import selectForgotPage from './selectors';
import reducer from './reducer';
import saga from './saga';
import SentIcon from './Sent.svg';
import messages from './messages';
import utilsMessages from 'utils/messages';

import { RootState } from './types';
import { submitForgetPassword, closeSentModal } from './actions';

interface OwnProps {
  forgotpage: any;
}

interface StateProps {}

interface DispatchProps {
  onSubmit: (data: any) => void;
  onCloseSentModal: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const ModalMessage = styled.div`
  text-align: center;
  margin-top: 20pt;
  margin-bottom: 18pt;
`;

export class ForgotPage extends React.PureComponent<Props> {
  public render() {
    const { isOpenSentModal } = this.props.forgotpage;
    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        <ForgotForm onSubmit={this.props.onSubmit} />
        <Modal isOpen={isOpenSentModal} submitLabel="Close" onSubmit={this.props.onCloseSentModal}>
          <PromptMessage
            icon={SentIcon}
            message={[
              <FormattedMessage {...utilsMessages.done} key="done" />,
              <FormattedMessage {...messages.requestSent} key="requestSent" />,
            ]}
          />
          <ModalMessage>
            <FormattedMessage {...messages.resetLink} />
            <br />
            <FormattedMessage {...messages.checkEmail} />
          </ModalMessage>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  forgotpage: selectForgotPage(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onSubmit: (data: any) => dispatch(submitForgetPassword(data)),
    onCloseSentModal: () => dispatch(closeSentModal()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'forgotPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'forgotPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(ForgotPage);
