/*
 *
 * ForgotPage reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  isOpenSentModal: null,
};

function forgotPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.CLOSE_SENT_MODAL:
      return {
        ...state,
        isOpenSentModal: false,
      };
    case ActionTypes.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        isOpenSentModal: true,
      };
    default:
      return state;
  }
}

export default forgotPageReducer;
