/*
 * ForgotPage Messages
 *
 * This contains all the text for the ForgotPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ForgotPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Tìm lại mật khẩu',
  },
  requestSent: {
    id: `${scope}.requestSent`,
    defaultMessage: 'Yêu cầu của bạn đã được gửi.',
  },
  resetLink: {
    id: `${scope}.resetLink`,
    defaultMessage: 'Một đường dẫn khôi phục mật khẩu đã được gửi.',
  },
  checkEmail: {
    id: `${scope}.checkEmail`,
    defaultMessage: 'Vui lòng kiểm tra email của bạn.',
  },
});
