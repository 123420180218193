import * as React from 'react';

import { MailOutlined } from '@ant-design/icons';

import { Input, Form } from 'antd';
import * as Sentry from '@sentry/browser';

import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PageHeader from '../PageHeader/PageHeader';
import media from 'utils/mediaStyle';
import messages from 'containers/ResetPage/messages';
import styled from 'styled-components';
import topBg from './top-bg.png';
import utilsMessages from 'utils/messages';

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  max-width: 720px;
  background-image: url(${topBg});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 8pt;
  ${media.md`
    padding: 30pt 90pt;
  `};
  p {
    line-height: normal;
    margin: 8px 0;
  }
`;

interface ForgotFormProps {
  onSubmit: (data: any) => void;
}

const ForgotForm: React.FC<ForgotFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm();

  return (
    <Container>
      <PageHeader size="large">
        <FormattedMessage {...messages.header} />
      </PageHeader>
      <Form
        form={form}
        onFinish={(value) => {
          onSubmit(value);
        }}
        onFinishFailed={(error) => {
          Sentry.captureException(error, { level: Sentry.Severity.Warning });
        }}
        className="login-form"
        layout="vertical"
        size="large"
      >
        <FormattedMessage {...utilsMessages.email}>
          {(email) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: 'Email' }}>
              {(fieldRequired) => (
                <Form.Item
                  name="email"
                  colon={false}
                  required={false}
                  label="Email"
                  hasFeedback
                  rules={[{ required: true, message: fieldRequired }]}
                >
                  <Input
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>

        <Form.Item style={{ marginTop: '12px' }}>
          <Button block type="primary" htmlType="submit">
            <FormattedMessage {...utilsMessages.reset} />
          </Button>
        </Form.Item>
        <p>
          <FormattedMessage {...utilsMessages.backto} />{' '}
          <Link to="/signin">
            <FormattedMessage {...utilsMessages.signin} />
          </Link>
        </p>
      </Form>
    </Container>
  );
};

export default ForgotForm;
